import React, { Component } from 'react'
import $ from 'jquery';
import { Link ,useLocation } from 'react-router-dom'
import styled from 'styled-components'

import './style.scss'
import{Profile} from './parts.jsx'


import {ReactComponent as HomeIcon} from '../../images/home.svg';
import {ReactComponent as ExploreIcon} from '../../images/explore.svg';
import {ReactComponent as Square4} from '../../images/square4.svg';
import {ReactComponent as TnsLogo} from '../../images/logo-sm.svg';

const MenuList = styled.ul.attrs({
  className: 'nav__menu',
})``
const MenuItem  = styled(Link).attrs(props=>({
  className : `nav__menu__item ${props.active === "true"?"active":""}`,
}))``;

const SubMenuList = styled.div.attrs({
  className: 'nav__more__list',
})``
const SubMenuItem  = styled(Link).attrs(props=>({
  className : `nav__more__list__item ${props.active === "true"?"active":""}`,
}))``;

const Menu = (props) =>{
  const { pathname } = useLocation();
  let morePath = ["/roadmap","/faq","/terminology"];
  return(
    <MenuList>
      <MenuItem to="/home" active={`${pathname === "/home"}`}><HomeIcon/>Home</MenuItem>
      <MenuItem to="/search/trx" active={`${pathname === "/explore"}`}><ExploreIcon/>Explore</MenuItem>
      <MenuItem to="/search" active={`${pathname === "/search"}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>Register</MenuItem>
      <button className={`nav__menu__item ${morePath.includes(pathname)?"active":""}`}>
        <Square4/>More

          <div className="nav__more__content">
            <SubMenuList>
              <h4 className="nav__more__list__head">Info</h4>
              <SubMenuItem to="/roadmap" active={morePath}>Roadmap</SubMenuItem>
              <SubMenuItem to="/faq" active={`${pathname === "/faq"}`}>Faq</SubMenuItem>
            </SubMenuList>
            <SubMenuList>
              <h4 className="nav__more__list__head">Dev Tools</h4>
              <SubMenuItem to="/terminology" active={`${pathname === "/terminology"}`}>Terminology</SubMenuItem>
            </SubMenuList>
            <SubMenuList>
              <h4 className="nav__more__list__head">Socials</h4>
                <a className="nav__more__list__item" href="https://twitter.com/DomainsTns" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg>Twitter</a>
                <a className="nav__more__list__item" href="https://telegram.me/domainstns" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.99432,2a10,10,0,1,0,10,10A9.99917,9.99917,0,0,0,11.99432,2Zm3.17951,15.15247a.70547.70547,0,0,1-1.002.3515l-2.71467-2.10938L9.71484,17.002a.29969.29969,0,0,1-.285.03894l.334-2.98846.01069.00848.00683-.059s4.885-4.44751,5.084-4.637c.20147-.189.135-.23.135-.23.01147-.23053-.36152,0-.36152,0L8.16632,13.299l-2.69549-.918s-.414-.1485-.453-.475c-.041-.324.46649-.5.46649-.5l10.717-4.25751s.881-.39252.881.25751Z"/></svg>Telegram</a>
            </SubMenuList>
          </div>
      </button>
    </MenuList>
  )
}

const Logo = () =>{

  return(
    <Link className="nav__logo" to="/"><TnsLogo/></Link>
  )
}


class Header extends Component{

  constructor(props){
    super(props)
    this.state = {
      scrollTop:$(window).scrollTop(),
    }
  }
  componentDidMount = () =>{
    window.addEventListener('scroll', this.onscroll,{ passive: true });
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onscroll,{ passive: true });
  }

  onscroll = () =>{
    let offset = $(window).scrollTop();
    this.setState({scrollTop:offset})
  }


  render(){
    let{isPopUp,address,scrollTop} = this.state;
    return(
      <div className={`navigation ${scrollTop < 100?"nav__lg":""}`}>
        <div className="navigation__body">
          <Logo />
          <Menu />
          <Profile />

        </div>
      </div>
    )
  }
}

export default Header;
