import React, {Component,Fragment,useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {useParams,useNavigate} from 'react-router-dom'
import $ from "jquery";
import './style.scss';

import tronWeb from "../../../tronweb"
import {Utils,NameHash,PopUp} from '../../../helper'
import {DetailsBody,AddressView,RecordAddrUpdate,RecordTextUpdate} from "../parts.jsx"

import {ReactComponent as PlusIcon} from '../../../images/plus.svg';
import {ReactComponent as MinusIcon} from '../../../images/minus.svg';
import {ReactComponent as ArrowIcon} from '../../../images/arrow-left.svg';


const StyledLink  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;



class Registrant extends React.Component {
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      isProcess:false,
      isPopUp:false,
      inputAddress:"",
      output:"",
    }
  }

  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
  }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false,output:""})
    }
  }

  handleSubmit =async() =>{
    try {
      let {isProcess,isPopUp,inputAddress} = this.state;
      let {record,tld,address} = this?.props?.data??{};

      if(!address) return $(".login__access").click();
      if(address !== record.registrant) return Utils.setToastAlert("Only registrant can transfer registration","warning");
      if(!inputAddress) return Utils.setToastAlert("Please enter registrant address","warning");
      if(inputAddress === record.name) return Utils.setToastAlert("Please enter new registrant TNS name","warning");
      if(inputAddress === record.registrant) return Utils.setToastAlert("Please enter new registrant address","warning");

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true,output:""})

      let result = await NameHash.nameToAddress(inputAddress);
      if(!result.success){
        this.setState({isProcess:false})
        return Utils.setToastAlert(result.msg,"warning");
      }
      if(result.output === record.registrant){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Please enter new registrant address or TNS name","warning");
      }

      this.setState({output:result.output})

      let tokenId = NameHash.nameToTokenid(record.name);

      let registrar = await window.tronWeb.contract().at(Utils.registrar(tld));
      await registrar.transferFrom(address,result.output,tokenId).send({
       feeLimit:100 * 1e6,
      })

      this.setState({isProcess:false,isPopUp:false,output:""},this.props.handler)
      Utils.setToastAlert("Domain registration transfer request submitted successfully","success")
    } catch (e) {
      // console.log(e);
      this.setState({isProcess:false,output:""})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to transfer registration","error")
      }
    }
  }


  render() {
    let {isProcess,isPopUp,inputAddress,output} = this.state;
    let {record,address} = this?.props?.data??{};

    return (
      <Fragment>
        <button
          className = "details__handle__btn"
          onClick={()=>this.setState({isPopUp:true})}
          disabled={!address || address !== record.registrant || isProcess || isPopUp}
          >
          Transfer
          <PopUp.ToolTip text="You can only transfer the registrant if you are the registrant and are logged into your wallet" />
        </button>
      {!!isPopUp && <PopUp.Modal
        className="modal__sm"
        title="Transfer Registrant"
        onClose={this.closePopUp}
        >
          <div className="record__popup__form">
            <p>Registrant : The owner of a registration. The registrant may transfer the registration, set the Controller, and reclaim ownership of the name in the registry if required.
              The registrant is the owner of the NFT token that represents the name.
            </p>
            <input
              className="record__popup__form__input"
              type="text"
              placeholder="Enter receiver address or TNS name"
              value={inputAddress}
              onChange={(e)=>this.setState({inputAddress:e.target.value})}
              readOnly={!!isProcess}
              />
            {!!output && <div className="record__popup__form__output">{output}</div>}

            <button
              disabled={!!isProcess}
              onClick={this.handleSubmit}
              >
              Transfer
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
      </PopUp.Modal>}
    </Fragment>
      )
  }
}

class Controller extends React.Component {
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      isProcess:false,
      isPopUp:false,
      inputAddress:"",
      output:"",
    }
  }

  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
  }

  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false,output:""})
    }
  }

  handleSubmit =async() =>{
    try {
      let {isProcess,isPopUp,inputAddress} = this.state;
      let {record,tld,address} = this?.props?.data??{};
      let {name,controller,registrant} = record || {};

      if(!address) return $(".login__access").click();
      if(address !== controller && address !== registrant) return Utils.setToastAlert("Only controller or registrant can transfer controller","warning");
      if(!inputAddress) return Utils.setToastAlert("Please enter controller address or TNS name","warning");
      if(inputAddress === name) return Utils.setToastAlert("Please enter new controller TNS name","warning");
      if(inputAddress === controller) return Utils.setToastAlert("Please enter new controller address","warning");

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true,output:""})

      let result = await NameHash.nameToAddress(inputAddress);
      if(!result.success){
        this.setState({isProcess:false})
        return Utils.setToastAlert(result.msg,"warning");
      }
      if(result.output === controller){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Please enter new controller address or TNS name","warning");
      }

      this.setState({output:result.output})


      await new Promise(async (resolve,reject) =>{
        try {
          if(address === controller){
            let node = NameHash.nameToNode(record.name);
            let registry = await window.tronWeb.contract().at(Utils.registry);
            await registry.setOwner(node,result.output).send({
             feeLimit:20 * 1e6,
            })
            resolve()
          }else {
            let name = record.name.split(".")[0]
            let registrar = await window.tronWeb.contract().at(Utils.registrar(tld));
            await registrar.reclaim(name,result.output).send({
             feeLimit:20 * 1e6,
            })
            resolve()
          }
        } catch (e) {
          reject(e)
        }
      })

      this.setState({isProcess:false,isPopUp:false,output:""},this.props.handler)
      Utils.setToastAlert("Domain controller transfer request submitted successfully","success")
    } catch (e) {
      this.setState({isProcess:false,output:""})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to transfer domain controller","error")
      }
    }
  }


  render() {
    let {isProcess,isPopUp,inputAddress,output} = this.state;
    let {record,address} = this?.props?.data??{};

    let {controller,registrant} = record || {};
    let isTransferable = !!address && (address === registrant || address === controller);

    return (
      <Fragment>
        <button
          className = "details__handle__btn"
          onClick={()=>this.setState({isPopUp:true})}
          disabled={!isTransferable || isProcess || isPopUp}
          >
          Transfer
          <PopUp.ToolTip text="You can only transfer the controller if you are the controller or registrant and are logged into your wallet" />
        </button>

      {!!isPopUp && <PopUp.Modal
        className="modal__sm"
        title="Transfer Controller"
        onClose={this.closePopUp}
        >
          <div className="record__popup__form">
            <p>Controller: The Controller of a name is the entity referenced in the TNS registry's owner field. A controller may transfer ownership, set a resolver or TTL, and create or reassign subdomains
              and may edit the records of a name. The Controller may be changed by the Registrant or Controller.</p>
            <input
              className="record__popup__form__input"
              type="text"
              placeholder="Enter address or TNS name"
              value={inputAddress}
              onChange={(e)=>this.setState({inputAddress:e.target.value})}
              readOnly={!!isProcess}
              />
            {!!output && <div className="record__popup__form__output">{output}</div>}
            <button
              disabled={!!isProcess}
              onClick={this.handleSubmit}
              >
              Transfer
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
      </PopUp.Modal>}
    </Fragment>
      )
  }
}

class Resolver extends React.Component {
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      isProcess:false,
      isPopUp:false,
      inputAddress:"",
    }
  }
  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
  }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false})
    }
  }

  handleSubmit =async() =>{
    try {
      let {isProcess,isPopUp,inputAddress} = this.state;
      let {record,address} = this?.props?.data??{};

      if(!address) return $(".login__access").click();
      if(address !== record.controller) return Utils.setToastAlert("Only controller can set resolver contract","warning");
      if(!inputAddress) return Utils.setToastAlert("Please enter resolver contract address","warning");
      if(!tronWeb.isAddress(inputAddress)) return Utils.setToastAlert("Please enter valid resolver contract address","warning");

      if(inputAddress === record.resolver) return Utils.setToastAlert("Please enter new resolver contract address","warning");
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let node = NameHash.nameToNode(record.name);
      let registry = await window.tronWeb.contract().at(Utils.registry);
      await registry.setResolver(node,inputAddress).send({
       feeLimit:20 * 1e6,
      })

      this.setState({isProcess:false,isPopUp:false},this.props.handler)
      Utils.setToastAlert("Domain resolver contract update request submitted successfully","success")
    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to update domain resolver contract","error")
      }
    }
  }


  render() {
    let {isProcess,isPopUp,inputAddress} = this.state;
    let {record,address} = this?.props?.data??{};

    return (
      <Fragment>
        <button
          className = "details__handle__btn"
          onClick={()=>this.setState({isPopUp:true})}
          disabled={!address || address !== record.controller || isProcess || isPopUp}
          >
          Set
          <PopUp.ToolTip text="You can only set the resolver if you are the controller and are logged into your wallet" />
        </button>
      {!!isPopUp && <PopUp.Modal
        className="modal__sm"
        title="Set Resolver"
        onClose={this.closePopUp}
        >
          <div className="record__popup__form">
            <p>Resolver: A resolver is a contract that maps from name to the resource (e.g., cryptocurrency addresses, text record, etc). Resolvers are pointed to by the resolver field of the registry.</p>
            <input
              className="record__popup__form__input"
              type="text"
              placeholder="Enter resolver contract address"
              value={inputAddress}
              onChange={(e)=>this.setState({inputAddress:e.target.value})}
              readOnly={!!isProcess}
              />
            <button
              disabled={!!isProcess}
              onClick={this.handleSubmit}
              >
              Set
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
      </PopUp.Modal>}
    </Fragment>
      )
  }
}

class ReverseRegistry extends React.Component {
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      isProcess:false,
      isPopUp:false,
    }
  }
  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
  }

  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false})
    }
  }

  handleSubmit =async() =>{
    try {
      let {isProcess,isPopUp} = this.state;
      let {record,address} = this?.props?.data??{};

      if(!address) return $(".login__access").click();
      if(address !== record.controller) return Utils.setToastAlert("Only controller can set reverse registrant","warning");

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let node = NameHash.nameToNode(record.name);
      let registry = await window.tronWeb.contract().at(Utils.registry);
      await registry.setReverseRecord(node).send({
       feeLimit:30 * 1e6,
      })

      this.setState({isProcess:false,isPopUp:false},this.props.handler)
      Utils.setToastAlert("Reverse registrant update request submitted successfully","success")
    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to set reverse registrant","error")
      }
    }
  }


  render() {
    let {isProcess,isPopUp,inputAddress} = this.state;
    let {record,address} = this?.props?.data??{};

    return (
      <Fragment>
        <button
          className = "details__handle__btn"
          onClick={()=>this.setState({isPopUp:true})}
          disabled={!address || address !== record.controller || isProcess || isPopUp}
          >
          Set
          <PopUp.ToolTip text="You can only set the reverse registrant if you are the controller and are logged into your wallet" />
        </button>
      {!!isPopUp && <PopUp.Modal
        className="modal__sm"
        title="Set Reverse Registrant"
        onClose={this.closePopUp}
        >
          <div className="record__popup__form">
            <p>Reverse registrant: The mapping from a TRON address (eg, T9yD14...) to a TNS name. This allows dapps to find and display your TNS name when you connect to them with your Tron account. You may own multiple domain or subdomain but address to TNS domain/subdomain mapping is unique. A address cannot map to two TNS domain/subdomain or vice versa.</p>
            <p>so,If your address is already associated to other TNS domain/subdomain in REVERSE REGISTRY, It will be automatically removed.</p>
            <input
              className="record__popup__form__input"
              type="text"
              value={address || "Connect wallet"}
              readOnly={true}
              />
            <button
              disabled={!!isProcess || address === record.reverse}
              onClick={this.handleSubmit}
              >
              Set
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
      </PopUp.Modal>}
    </Fragment>
      )
  }
}


class Renew extends React.Component {
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      isProcess:false,
      isPopUp:false,
      duration:1,
      rent:3170,
    }
  }
  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
  }
  componentDidMount(){
    let {record,tld,address} = this?.props?.data??{};
    let name = record.name || "";
    let chars = name.split(".")[0].length;
    this.setState({rent:Utils.rent(tld,chars)})
  }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false})
    }
  }

  handleSubmit =async() =>{
    try {
      let {isProcess,isPopUp,duration,rent} = this.state;
      let {record,tld,address} = this?.props?.data??{};

      if(!address) return $(".login__access").click();
      if(address !== record.registrant) return Utils.setToastAlert("Only registrant can renew registration","warning");
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      duration = duration*365*24*60*60;

      let instance = await window.tronWeb.contract().at(Utils.registrar(tld));
      let credit = await instance.getFreeCredit(address).call();
      credit = tronWeb.toDecimal(credit);


      let cost = Utils.ceil(rent*duration/1e4);

      if(credit > 0){
        let _cost = cost > credit?cost-credit:0;
        Utils.setToastAlert(`Updated renewable cost is ${Utils.ceil(_cost/1e6,0)} TRX + ${Utils.ceil((cost - _cost)/1e6,0)} free credit`,"info");
        cost = _cost;
      }

      let balance = await new Promise(async resolve =>{
          try {
            if(!cost) return resolve(0)
            let _val = await window.tronWeb.trx.getUnconfirmedBalance(address);
            resolve(Utils.floor(_val))
          } catch (e) {
            resolve(0)
          }
        })

      if(balance < cost){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not sufficient TRX balance");
      }

      let _label = record.name.split(".")[0];
      await instance.renew(_label,duration).send({
       feeLimit:50 * 1e6,
       callValue:cost,
      });

      this.setState({isProcess:false,isPopUp:false},this.props.handler)
      Utils.setToastAlert("Domain registration renewal request submitted successfully","success")
    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to renew registration","error")
      }
    }
  }

  durationBtn = (type) =>{
    let{duration} = this.state;
    if(type === "minus"){
      duration = duration>1?duration-1:duration;
      duration = duration<=10000?duration:10000;
    }else {
      duration += 1;
    }
    this.setState({duration})
  }

  handleDuration = (e) =>{
    let{duration} = this.state;
    let value = e.target.value;
    value = parseInt(value);
    duration = value>=1?value:duration;
    duration = duration<=10000?duration:10000;
    this.setState({duration})
  }

  render() {
    let {isProcess,isPopUp,duration,rent} = this.state;
    let {record,address} = this?.props?.data??{};

    return (
      <Fragment>
        <button
          className = "details__handle__btn"
          onClick={()=>this.setState({isPopUp:true})}
          disabled={!address || address !== record.registrant || isProcess || isPopUp}
          >
          Extend
          <PopUp.ToolTip text="You can only renew domain in TNS TLD if you are the registrant and are logged into your wallet" />
        </button>
      {!!isPopUp && <PopUp.Modal
        className="modal__sm"
        title="Renew Domain"
        onClose={this.closePopUp}
        >
          <div className="record__popup__form">
            <p>If you do not renew the domain by the expiration date, the name will enter a 90 Days renewal grace period. If the registry grace period ends, the domain will be returned to the registry and You can't make any updates to the domain. A domain renewal extends your ownership of your domain name in TNS registrar TLD.</p>
              <div className="name__renew__input">
                <button onClick={()=>this.durationBtn("minus")}><MinusIcon/></button>
                <input
                  type="number"
                  value={duration}
                  onChange={this.handleDuration}
                  autoFocus={true}
                  />
                <p>Year</p>
                <button onClick={()=>this.durationBtn("add")}><PlusIcon/></button>
              </div>
              <span>renewal cost : {Utils.pretty.ceil(rent*duration*365*24*60*60/1e10,0)} TRX</span>

            <button
              disabled={!!isProcess}
              onClick={this.handleSubmit}
              >
              Renew
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
      </PopUp.Modal>}
    </Fragment>
      )
  }
}


class Details extends Component{
  constructor(props){
    super(props)
    this.unmounted = false;

    this.state = {
      domain:this?.props?.params?.domain??"",
      tld:this?.props?.tld??"trx",
      address:"",
      isLoading:false,
      showMore:false,
      record:{},
      addresses:[],
      texts:[],
    }
  }
  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
    document.title = "Tron Name Service";
  }
  componentDidMount(){
    document.title = "Details | TNS";
    Utils.getUser((err,account)=>{
      if(err) return console.log(err);
      let {address} = account;
      this.setState({address})
    })
    this.fetchDomainDetails()
  }
  componentDidUpdate() {
    let query = this.props?.params?.domain??"";
    let{domain} = this.state;
    if(!!query && query !== domain){
      let tld = Utils.getTld(query)
      this.setState({domain:query,tld,record:{},addresses:[],texts:[]},this.fetchDomainDetails);
    }
  }

  fetchDomainDetails = async() =>{
    try {
      let {domain} = this.state;
      let parent = domain.split(".").filter((_,i)=>i>0).join(".");
      this.setState({record:{parent}});

      await this.fetchRegistry();
      if(!this.state.record.name) return;

      await this.fetchRegistrar()
      this.fetchAddresses()

    } catch (e) {

    }
    finally{
      document.title = `Details - ${this.state.domain}`;
    }
  }

  fetchRegistry = async() =>{
    try {
      let {domain,isLoading} = this.state;
      this.setState({isLoading:true})
      if(isLoading) return;

      let node = NameHash.nameToNode(domain);
      let registry = await tronWeb.contract().at(Utils.registry);
      let [controller,resolver,reverse,,name] = await registry.getRecord(node).call();
      resolver = !Utils.isZeroAddress(resolver)?resolver:Utils.resolver;

      this.setState(prevState => ({
        isLoading:false,
        record: {
          ...prevState.record,
          name,
          controller:tronWeb.address.fromHex(controller),
          resolver:tronWeb.address.fromHex(resolver),
          reverse:tronWeb.address.fromHex(reverse),
        }
      }))

    } catch (e) {
      this.setState(prevState => ({
        isLoading:false,
        record: {
          ...prevState.record,
          name: "",
          controller:"",
          resolver:"",
          reverse:"",
        }
      }))
    }
  }

  fetchRegistrar = async() =>{
    try {
      let {domain,tld,isLoading} = this.state;
      this.setState({isLoading:true})
      if(isLoading) return;

      let tokenId = NameHash.nameToTokenid(domain);
      if(!tokenId){
        return this.setState(prevState => ({
          isLoading:false,
          record: {
            ...prevState.record,
            registrant:"",
            expiry:0,
          }
        }))
      }


      let registrar = await tronWeb.contract().at(Utils.registrar(tld));
      let registrant = await registrar.ownerOf(tokenId).call();
      registrant = registrant._owner?registrant._owner:registrant;

      if(!!Utils.isZeroAddress(registrant)) {
        return this.setState(prevState => ({
          isLoading:false,
          record: {
            ...prevState.record,
            registrant:Utils.Zero_address_hex,
            expiry:0,
          }
        }))
      }

      let expiry = await registrar.methods["nameExpires(uint256)"](tokenId).call();
      expiry = expiry._hex?expiry._hex:expiry;
      expiry = tronWeb.toDecimal(expiry);

      this.setState(prevState => ({
        isLoading:false,
        record: {
          ...prevState.record,
          registrant : tronWeb.address.fromHex(registrant),
          expiry,
        }
      }))

    } catch (e) {
      this.setState(prevState => ({
        isLoading:false,
        record: {
          ...prevState.record,
          registrant:"",
          expiry:0,
        }
      }))
    }
  }

  fetchAddresses = async() =>{
    try {

      let {domain,record,isLoading} = this.state;
      this.setState({isLoading:true})
      if(isLoading) return;

      let node = NameHash.nameToNode(domain);

      let resolver = await tronWeb.contract().at(record.resolver || Utils.resolver);

      let _chainIds = Utils.chainIds();
      let addresses = await resolver.getAddrs(node,_chainIds).call();
      addresses = addresses.map((item,i) => NameHash.addressEncoder(_chainIds[i],item))

      let texts = await resolver.getTexts(node,Utils.textKeys()).call();

      this.setState({addresses,texts,isLoading:false})
    } catch (e) {
      this.setState({addresses:[],texts:[],isLoading:false})
    }
  }

  renderAddresses = () =>{
    let {addresses,record,address} = this.state;

    return(
      <div className="name__record__details__box">
        <h3>
          ADDRESSES
        </h3>
        <ul>
           {Utils.chainSymbol().map((key,i) => {
             let value = !!addresses[i]?addresses[i]:!!record.controller?"Not set":false;
            return(<li key={key}>
              <div className="name__record__details__key">
                {key}
              </div>
              <div className="name__record__details__value">
                {<AddressView data={{address:value,anchor:i===0}}/>}
                <RecordAddrUpdate data={{key,value:addresses[i],record,address}} handler={this.fetchAddresses}/>
              </div>
            </li>)
          })}
        </ul>
      </div>
    )
  }
  renderTexts = () =>{
    let {texts,record,address} = this.state;
    return(
      <div className="name__record__details__box">
        <h3>
          TEXT RECORD
        </h3>
        <ul>
           {Utils.allTexts().map(({key,prefix,anchor},i) => {
             let value = texts[i] || "";
             let isAnchor = value && anchor;
             if(!!value){
               let _initial = value.substring(0,prefix.length).toLowerCase();
               value = _initial === prefix?value:prefix+value;
             }

            return(<li key={key}>
              <div className="name__record__details__key">
                {key}
              </div>
              <div className="name__record__details__value">
                {!isAnchor && <div className="name__record__text">{value?value:!!record.controller?"Not set":"--"}</div>}
                {!!isAnchor && <StyledLink className="name__record__text" href={value}>{value}</StyledLink>}
                <RecordTextUpdate data={{key,value,record,address}} handler={this.fetchAddresses} key={key+value}/>
              </div>
            </li>)
          })}
        </ul>
      </div>
    )
  }

  render(){
    let{address,domain,tld,record,showMore} = this.state;

    let parent = domain.split(".").filter((_,i)=>i>0).join(".");
    return(
      <div className="name__details">
        <div className="name__registry__details">
          <div className="name__registry__details__each">
            <h4>PARENT</h4>
            {!!record.parent && <Link to={`/name/${record.parent}/details`}>{record.parent}</Link>}
            {!record.parent && <p>--</p>}
          </div>
          <div className="name__registry__details__each">
            <h4>REGISTRANT</h4>
            <AddressView data={{address:record.registrant,anchor:true}}/>
            <Registrant data={{address,tld,record}} handler={this.fetchRegistrar}/>
          </div>
          <div className="name__registry__details__each">
            <h4>CONTROLLER</h4>
            <AddressView data={{address:record.controller,anchor:true}}/>
            <Controller data={{address,tld,record}} handler={this.fetchRegistry}/>
          </div>

          <div className="name__registry__details__each">
            <h4>EXPIRATION DATE</h4>
            <p>{!!record.expiry?Utils.getTimeInGMT(record.expiry*1000):"--"}</p>
            <Renew data={{address,tld,record}} handler={this.fetchRegistrar} key={record.name}/>
          </div>

          <div className={`advance__settings${showMore?" active":""}`} onClick={()=>this.setState({showMore:!showMore})}>Advance settings <ArrowIcon/></div>
            {!!showMore && <div className="name__registry__details__each">
              <h4>REVERSE REGISTRANT</h4>
              <AddressView data={{address:record.reverse,anchor:true}}/>
              <ReverseRegistry data={{address,record}} handler={this.fetchRegistry}/>
            </div>}
          {!!showMore && <div className="name__registry__details__each">
            <h4>RESOLVER</h4>
            <AddressView data={{address:record.resolver,anchor:true}}/>
            <Resolver data={{address,record}} handler={this.fetchRegistry}/>
          </div>}
        </div>

        <div className="name__record__details">
          <div className="name__record__title">
            <p>RECORDS</p>
          </div>
          {this.renderAddresses()}
          {this.renderTexts()}
        </div>

      </div>
    )
  }
}





function DetailsWithProps(props){
    const params = useParams();
    const navigate = useNavigate();
    let tld = Utils.getTld(params.domain);
    params.domain = NameHash.sanitizeDomainName(params.domain,tld);

    return (
      <DetailsBody data={{params,tld,tab:"details"}}>
        <Details
            {...props}
            params={params}
            navigate={navigate}
            tld={tld}
        />
      </DetailsBody>
    );
}

export default DetailsWithProps;
