import React, {Component,Fragment } from 'react'
import { Link } from 'react-router-dom'
import {useParams,useNavigate} from 'react-router-dom'
import $ from "jquery";

import tronWeb from "../../../tronweb"
import {Utils,NameHash,PopUp} from '../../../helper'
import {DetailsBody} from "../parts.jsx"
import {Models} from '../../../components'
import './style.scss';

import {ReactComponent as ArrowMark} from '../../../images/arrow.svg';

class CreateSubDomain extends React.Component {
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      isProcess:false,
      isPopUp:false,
      name:"",
    }
  }

  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
  }
  closePopUp = () => {
    if(!this.state.isProcess){
      this.setState({isPopUp:false})
    }
  }

  handleInput = (e) =>{
    let value = e.target.value;
    value = value.toLowerCase();

    if(!!value && value !== NameHash.sanitize(value,true)) return;
    this.setState({name:value})
  }

  handleSubmit =async() =>{
    try {
      let {isProcess,isPopUp,name} = this.state;
      let {domain,address,controller} = this?.props?.data??{};

      if(!address) return $(".login__access").click();
      if(address !== controller) return Utils.setToastAlert("Only controller can create subdomain","warning");
      if(!name) return Utils.setToastAlert("Please enter label for subdomain","warning");

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})


      let node = NameHash.nameToNode(domain);
      let registry = await window.tronWeb.contract().at(Utils.registry);
      await registry.setSubnodeOwner(node,name,address).send({
       feeLimit:100 * 1e6,
      })

      this.setState({isProcess:false,isPopUp:false},this.props.handler)
      Utils.setToastAlert("Subdomain create request submitted successfully","success");
      await Utils.wait(1000);
      window.location.href = `/name/${name}.${domain}/details`
    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to create subdomain","error")
      }
    }
  }


  render() {
    let {isProcess,isPopUp,name} = this.state;
    let {domain,controller,address} = this?.props?.data??{};

    let isController = !!address && address === controller;
    let _fullDomain = `${name}.${domain}`;
    return (
      <Fragment>
        {!!isController && <button
          className="subdomain__create"
          onClick={()=>this.setState({isPopUp:true})}
          disabled={isProcess || isPopUp}
          >
          <ArrowMark/>Create Subdomain
          <PopUp.ToolTip text="You can only create the subdomain if you are the controller and are logged into your wallet" />
        </button>}

      {!!isPopUp && !!isController &&<PopUp.Modal
        className="modal__sm"
        title="Create Subdomain"
        onClose={this.closePopUp}
        >
          <div className="subdomain__create__form">
            <p>Subdomain: A TNS subdomain is a nth-level (NLD) domain. TNS uses the same syntax as DNS (a dot separated right-to-left). For example, wallet.tron.tns is 3LD.2LD.TLD, It is third-level (3LD) domain that precedes both the second-level domain (2LD) and the top-level domain (TLD).
              Currently, if you own a NLD you can create as many subdomains (n+1th level domain) as you want.</p>
            <input
              className="subdomain__create__form__input"
              type="text"
              placeholder="Enter subdomain name"
              value={name}
              onChange={this.handleInput}
              readOnly={!!isProcess}
              />
            <div className="subdomain__create__form__output">{_fullDomain}</div>
            <button
              disabled={!!isProcess}
              onClick={this.handleSubmit}
              >
              Create
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
      </PopUp.Modal>}
    </Fragment>
      )
  }
}



class SubDomains extends Component{
  constructor(props){
    super(props)
    this.unmounted = false;

    this.state = {
      unmounted:false,
      domain:this?.props?.params?.domain??"",
      tld:this?.props?.tld??"trx",
      controller:"",
      count:0,
      address:"",
      isLoading:false,
      domains:[],
    }
  }
  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
    document.title = "Tron Name Service"
  }

  componentDidMount(){
    document.title = "SubDomains | TNS";
    Utils.getUser((err,account)=>{
      if(err) return console.log(err);
      const {address} = account;
      this.setState({address})
    })
    this.fetchRegistry()
  }
  fetchRegistry = async() =>{
    try {
      let {domain,isLoading} = this.state;
      this.setState({isLoading:true})
      if(isLoading) return;

      let node = NameHash.nameToNode(domain);
      let registry = await tronWeb.contract().at(Utils.registry);
      let controller = await registry.ownerOf(node).call();
      controller = !Utils.isZeroAddress(controller)?tronWeb.address.fromHex(controller):"";
      if(!controller) return this.setState({isLoading:false,controller:"",count:0});

      let count = await registry.subDomainCount([node]).call()
      count = tronWeb.toDecimal(count[0]);
      this.setState({isLoading:false,controller,count},this.fetchDomains)

    } catch (e) {
      this.setState({isLoading:false,controller:"",count:0})
    }
    finally{
      document.title = `Subdomains - ${this.state.domain}`;
    }
  }


  fetchDomains = async() =>{
    try {
      let {domain,tld,domains,count} = this.state;
      if(!count || domains.length >= count)return;
      let limit = count - domains.length >= 10?10:count - domains.length;
      let start = count - domains.length - limit;

      this.setState({isLoading:true})
      let node = NameHash.nameToNode(domain);
      let registry = await tronWeb.contract().at(Utils.registry);

      let nodes = await registry.searchNodeSubDomains(node,start,limit).call();
      nodes = nodes.reverse();
      let names = await registry.methods["getNames(bytes32[])"](nodes).call();

      let _domains = names.map(i=>{
        return{name:`${i}`,expiry:0}
      })

      await new Promise(async(resolve)=>{
        try {
          if(domain !== tld)return resolve();
          let _tokenIds = names.map(i => NameHash.nameToTokenid(i));

          let registrar = await tronWeb.contract().at(Utils.registrar(tld));
          let owners = await registrar.methods["ownersOf(uint256[])"](_tokenIds).call();

          if(!owners.find(i => !Utils.isZeroAddress(i))) return resolve()
          owners = owners.map(i => tronWeb.address.fromHex(i));

          let expiries = await registrar.methods["nameExpires(uint256[])"](_tokenIds).call();
          expiries = expiries.map(i => tronWeb.toDecimal(i));

          _domains.map((item,i)=>{
            item.owner = owners[i];
            item.expiry = expiries[i]
            return item;
          })
          resolve()
        } catch (e) {
          resolve()
        }
      })

      domains = [...domains,..._domains];

      this.setState({isLoading:false,domains})
    } catch (e) {
        this.setState({isLoading:false})
    }
  }


  render(){
    let {domain,domains,isLoading,address,count,controller} = this.state;

    let isLoad = !!count && count >10 && count > domains.length;

    return(
      <Fragment>
        {!isLoading && <div className="subdomain__header">
          {!!count && <p className="subdomain__count">{count} Results</p>}
          <CreateSubDomain data={{domain,address,controller}} />
        </div>}

        {!!domains.length && <Models.RenderDomains
          data={{domains,isLoading,address}}
          />}
        {!!isLoading && <Models.RenderDomains
          data={{isLoading,dummy:4}}
          />}
        {!count && !isLoading && <h2 className="noresult__notice">No Subdomains have been added</h2>}

        {!!isLoad && <button
          className="subdomain__loadmore"
          onClick={this.fetchDomains}
          disabled={isLoading}
          >
          Load more
          {!!isLoading && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </button>}
      </Fragment>
    )
  }
}


function SubDomainsWithProps(props){
    const params = useParams();
    const navigate = useNavigate();
    let tld = Utils.getTld(params.domain);
    params.domain = NameHash.sanitizeDomainName(params.domain,tld);

    return (
      <DetailsBody data={{params,tld,tab:"subdomains"}}>
        <SubDomains
            {...props}
            params={params}
            navigate={navigate}
            tld={tld}
        />
      </DetailsBody>
    );
}

export default SubDomainsWithProps;
