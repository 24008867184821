
import React, {Component } from 'react'
import { Link } from 'react-router-dom'
import {useParams,useNavigate} from 'react-router-dom'
import $ from "jquery";

import tronWeb from "../../../tronweb"
import {Utils,NameHash} from '../../../helper'
import {DetailsBody} from "../parts.jsx"

import {ReactComponent as PlusIcon} from '../../../images/plus.svg';
import {ReactComponent as MinusIcon} from '../../../images/minus.svg';
import './style.scss';

class Register extends Component{
  constructor(props){
    super(props)
    this.unmounted = false;

    this.state = {
      domain:this?.props?.params?.domain??"",
      tld:this?.props?.tld??"trx",
      address:"",
      isLoading:false,
      isProcess:false,
      duration:1,
      price:1e8,
      rent:3170,
      //0 fetching,1 subdomain, 2 bought, 3 ready,4 parent
      status:0,
      credit:0,
    }
  }
  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    this.unmounted = true;
    document.title = "Tron Name Service";
  }
  componentDidMount(){
    document.title = "Register | TNS";

    Utils.getUser((err,account)=>{
      if(err) return console.log(err);
      const {address} = account
      this.setState({address,credit:0},this.fetchCredit)
    })

    this.fetchRecord()
  }

  fetchCredit = async() =>{
    try {
      let{address,tld} = this.state;
      if(!address) return;
      let instance = await tronWeb.contract().at(Utils.registrar(tld));
      let credit = await instance.getFreeCredit(address).call();
      credit = tronWeb.toDecimal(credit);
      this.setState({credit})
    } catch (e) {
      this.setState({credit:0})
    }
  }

  fetchRecord = async() =>{
    try {
      let{domain,tld} = this.state;
      this.setState({isLoading:true})

      let lables = domain.split(".");
      let chars = lables[0].length;

      if(lables.length <= 1){
        // subdomain
        return this.setState({isLoading:false,status:4})
      }
      if(lables.length > 2){
        // subdomain
        return this.setState({isLoading:false,status:1})
      }

      let tokenId = NameHash.nameToTokenid(domain);
      let instance = await tronWeb.contract().at(Utils.registrar(tld));
      let _owner = await instance.ownerOf(tokenId).call();
      if(!Utils.isZeroAddress(_owner)){
        //already registerd
        return this.setState({isLoading:false,status:2})
      }
      this.setState({isLoading:false,price:Utils.fees(tld,chars),rent:Utils.rent(tld,chars),status:3})

    } catch (e) {
      // console.log(e);
      this.setState({isLoading:false,status:0})
    }finally{
      document.title = `Register - ${this.state.domain}`;
    }
  }

  durationBtn = (type) =>{
    let{duration} = this.state;
    if(type === "minus"){
      duration = duration>1?duration-1:duration;
      duration = duration<=10000?duration:10000;
    }else {
      duration += 1;
    }
    this.setState({duration})
  }

  handleDuration = (e) =>{
    let{duration} = this.state;
    let value = e.target.value;
    value = parseInt(value);
    duration = value>=1?value:duration;
    duration = duration<=10000?duration:10000;
    this.setState({duration})
  }

  handleRegister = async() =>{
    try {
      let{address,domain,tld,duration,price,rent,credit,isProcess} = this.state;

      if(!address) return $(".login__access").click();
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      duration = (duration-1)*365*24*60*60;

      let _total = Utils.ceil(price+(rent*duration/1e4));
      _total = credit>0?_total-credit:_total;
      _total = _total>0?_total:0;

      let balance = await new Promise(async resolve =>{
          try {
            if(!_total) return resolve(0)
            let _val = await window.tronWeb.trx.getUnconfirmedBalance(address);
            resolve(Utils.floor(_val))
          } catch (e) {
            resolve(0)
          }
        })

      if(balance < _total){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not sufficient TRX balance");
      }

      let _label = domain.split(".")[0];
      let instance = await window.tronWeb.contract().at(Utils.registrar(tld));
      await instance.register(_label,address,duration).send({
       feeLimit:200 * 1e6,
       callValue:_total,
      });


      Utils.setToastAlert("Domain registration request submitted successfully","success")
      this.setState({isProcess:false})
      await Utils.wait(3000);
      window.location.href = `/name/${domain}/details`
    } catch (e) {
      // console.log(e);
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to register domain","error")
      }
    }
  }
  render(){
    let{duration,price,rent,status,credit,isProcess,domain,tld} = this.state;
    let _netDuration = (duration - 1)*365*24*60*60;
    return(
      <div className="name__register">
        {status === 0 && <h2 className="noresult__notice">Please wait a moment</h2>}
        {status === 1 && <h2 className="noresult__notice">This name is a subdomain</h2>}
        {status === 2 && <h2 className="noresult__notice">This name is already registered</h2>}
        {status === 4 && <h2 className="noresult__notice">{domain === tld?"Parent TNS domain":"Invalid parent domain"}</h2>}

        {status === 3 && <div className="name__register__body">
          <div className="name__register__input">
            <button onClick={()=>this.durationBtn("minus")}><MinusIcon/></button>
            <input
              type="number"
              value={duration}
              onChange={this.handleDuration}
              autoFocus={true}
              />
            <p>Year</p>
            <button onClick={()=>this.durationBtn("add")}><PlusIcon/></button>
          </div>
          <p className="name__register__label">Registration Period</p>
          <div className="name__register__sum">
            {Utils.pretty(price/1e6,0)} TRX + {Utils.pretty.ceil(rent*_netDuration/1e10,0)} TRX = {Utils.pretty((price+(rent*_netDuration/1e4))/1e6,0)} TRX
          </div>
          <p className="name__register__label">Registration price to pay</p>

          <button className="name__register__submit"
            onClick={this.handleRegister}
            disabled={!!isProcess}
            >Register
            {!!isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </button>
          {!!credit && <p className="name__register__label" style={{marginTop:"5px"}}>Credit score: {Utils.pretty(credit/1e6,2)}</p>}

        </div>}
      </div>
    )
  }
}


function RegisterWithProps(props){
    const params = useParams();
    const navigate = useNavigate();
    let tld = Utils.getTld(params.domain);
    params.domain = NameHash.sanitizeDomainName(params.domain,tld);

    return (
      <DetailsBody data={{params,tld,tab:"register"}}>
        <Register
            {...props}
            params={params}
            navigate={navigate}
            tld={tld}
        />
      </DetailsBody>
    );
}

export default RegisterWithProps;
