
import tronWeb from "../tronweb"
import { formatsByName, formatsByCoinType } from '@ensdomains/address-encoder';
import Utils from "./utils"

const ZERO_Byte32 = "0000000000000000000000000000000000000000000000000000000000000000";

function isEmoji(str="") {
    let ranges = [
        '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])' // U+1F680 to U+1F6FF
    ];
    if (str.match(ranges.join('|'))) {
        return true;
    } else {
        return false;
    }
}

function sanitize(name,removeDot = false) {
  if(!name) return "";

  name = name.split('.').map(i=>[...i]).flat(1).some(char => {
    let code = char.charCodeAt(0);
     let _strTest = code < 48 || (code >= 58 && code <= 64) || (code >= 91 && code <= 96) || code > 122;
     if(!_strTest) return _strTest;
     return !isEmoji(char);
  })?"":name;

  if(!!removeDot && !!name){
    name = name.split('.').join("");
  }
  return name;
}

const sanitizeDomainName = (domain = "",tld = "tns") =>{

  if(!tronWeb.isAddress(domain)){
    domain = domain.toLowerCase();
    domain = sanitize(domain);

    let _labels = domain.split(".").filter(i => !!i);
    let _lastIndex = !!_labels.length?_labels.length - 1:0;
    if(_labels[_lastIndex] !== tld){
      _labels.push(tld);
    }
    domain = _labels.join(".");
  }else {
    domain = domain.toLowerCase();
    domain = `${domain}.${tld}`
  }
  return domain;
}


const labelhash = (name="") =>{
  return tronWeb.sha3(name);
}

const nameToNode = (name="") =>{
  let node = ZERO_Byte32;
  if(name) {
    let labels = name.split('.')
    for(let i = labels.length - 1; i >= 0; i--) {
      let labelSha = labelhash(labels[i]).substring(2);
      node = tronWeb.sha3(Buffer.from(node + labelSha, 'hex')).substring(2);
    }
  }
  return '0x' + node
}

const nameToTokenid = (name="") =>{
  let labels = name.split('.');
  if(labels.length !== 2) return 0;
  return tronWeb.BigNumber(labelhash(labels[0])).toString(10)
}

const tokenIdToNode = (id,tld = "tns") =>{
  id = tronWeb.toHex(id).substring(2);
  id = !!id && id.length % 2 === 0?id:"0"+id;
  return tronWeb.sha3(Buffer.from(nameToNode(tld).substring(2)+id, 'hex'))
}

const bytesToAddress = (address) =>{
  if(tronWeb.isAddress(address)){
    return address;
  }
  return tronWeb.toAscii(address)
}



function hexStringToUint8Array(hexString){
  if (hexString.length % 2 !== 0) throw "Invalid hexString";

  let arrayBuffer = new Uint8Array(hexString.length / 2);

  for (let i = 0; i < hexString.length; i += 2) {
    let byteValue = parseInt(hexString.substr(i, 2), 16);
    if (isNaN(byteValue)){
      throw "Invalid hexString";
    }
    arrayBuffer[i/2] = byteValue;
  }

  return Buffer.from(arrayBuffer);
}

// Custom chainId modify
// BSC MATIC
let  customChainIds = [9006,966];
const addressDecoder = (id = 195,value="") =>{
  if(!value) return [];

  id = customChainIds.includes(id)?60:id;
  let _uint8Array = formatsByCoinType[id].decoder(value);
  if(id === 195){
    let _newArr = [..._uint8Array]
    _newArr.shift();
    _uint8Array = Buffer.from(new Uint8Array(_newArr));
  }
  return _uint8Array;
  // return data.toString('hex');
}
const addressEncoder = (id = 195,value=[]) =>{
  try {
    if(!value || value === "0x") return "";
    id = customChainIds.includes(id)?60:id;
    if(id === 195){
      value = "0x41"+value.substring(2)
    }
    let _list = hexStringToUint8Array(value.substring(2));
    return formatsByCoinType[id].encoder(_list);
  } catch (e) {
    return "";
  }
}

const getCoinType = (key = "TRX") => formatsByName[key].coinType;




const nameToAddress = async(input = "",tld = "tns") =>{
  try {
    if(!input) return({success:false,msg:`Please provide address or ${tld} name`});
    input = input.trim();

    if(tronWeb.isAddress(input)){
      return({success:true,input,output:input})
    }
    input = input.toLowerCase();
    let labels = input.split(".");
    if(!labels.length || labels[labels.length - 1] !== tld) return({success:false,msg:`Please provide valid ${tld} name`})

    let node = nameToNode(input);
    let registry = await tronWeb.contract().at(Utils.registry);
    let [controller,resolver,,,] = await registry.getRecord(node).call();
    if(Utils.isZeroAddress(controller)) return({success:false,msg:`"${tld} name does not exists"`});

    resolver = !Utils.isZeroAddress(resolver)?tronWeb.address.fromHex(resolver):Utils.resolver;
    let publicResolver = await tronWeb.contract().at(resolver);

    let addr = await publicResolver.methods["addr(bytes32)"](node).call();
    addr = !Utils.isZeroAddress(addr)?addr:controller;
    addr = tronWeb.address.fromHex(addr);

    return({success:true,input,output:addr})
  } catch (e) {
      return({success:false,msg:"failed to resolve address"})
  }
}


const resolveAddress = async(address = "") =>{
  try {
    if(!address || !tronWeb.isAddress(address)) return "";
    let registry = await tronWeb.contract().at(Utils.registry);
    return await registry.methods["getName(address)"](address).call();
  } catch (e) {
      return "";
  }
}

const exportFns ={
  isEmoji,
  sanitize,
  sanitizeDomainName,

  labelhash,
  nameToNode,
  nameToTokenid,
  tokenIdToNode,
  bytesToAddress,

  addressDecoder,
  addressEncoder,
  getCoinType,

  nameToAddress,
  resolveAddress,
}

export default exportFns
