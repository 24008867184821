import React, { Component,useEffect } from 'react'

const Roadmap = () =>{
  useEffect(() => {
    document.title = "Roadmap | Tron Name Service";
    return () => {
        document.title = "Tron Name Service";
    }
  })

  return(
    <div className="main">
      <div className = "large__title">
        <h1>Roadmap</h1>
        <p>Timeline based on registration</p>
      </div>


      <div className="roadmap">
        <div className="roadmap__stage roadmap__stage__left">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>500</h2></div>
            <div className="stage__desc">
              Telegram bot, Twitter bot and other Listings.
            </div>
          </div>
        </div>
        <div className="roadmap__stage">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>2,000</h2></div>
            <div className="stage__desc">
              Integrated web wallet for TRX, TRC20 and TRC721 tokens.
            </div>
          </div>
        </div>
        <div className="roadmap__stage roadmap__stage__left">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>10,000</h2></div>
            <div className="stage__desc">
              Website hosting.
            </div>
          </div>
        </div>

        <div className="roadmap__stage">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>20,000</h2></div>
            <div className="stage__desc">
              Web messaging app for interaction between users.
            </div>
          </div>
        </div>

        <div className="roadmap__stage roadmap__stage__left">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>30,000</h2></div>
            <div className="stage__desc">
              Content sharing with Blogging.
            </div>
          </div>
        </div>

        <div className="roadmap__stage">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>35,000</h2></div>
            <div className="stage__desc">
            0 fee Marketplace (No Royalty)
            </div>
          </div>
        </div>

        <div className="roadmap__stage roadmap__stage__left">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>40,000</h2></div>
            <div className="stage__desc">
              Full fledge social media webapp
            </div>
          </div>
        </div>

        <div className="roadmap__stage">
          <div className="roadmap__line">&nbsp;</div>
          <div className="roadmap__content">
            <div className="stage__title"><h2>50,000</h2></div>
            <div className="stage__desc">
              Blockchain Explorer integrated with TNS names
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



export default Roadmap;
