import React, { Component,useEffect } from 'react'



const Faq = () =>{

  useEffect(() => {
    document.title = "Frequently Asked Questions | TNS";
    return () => {
        document.title = "Tron Name Service";
    }
  })

// https://app.ens.domains/faq
// https://docs.ens.domains/frequently-asked-questions

const all = [
  {
    ques:"What is TLD?",
    ans:'TLD stands for Top Level Domain, or name suffix. ".trx" , ".tron" , ".usdd" and ".tns" are TLDs.'
  },
  {
    ques:"Can I register a TLD of my own in the TNS?",
    ans:"No. We consider TNS to be part of the 'global namespace', so we do our best not to pollute that namespace."
  },
  {
    ques:"What is the maximum length of a name I can register?",
    ans:"There is no limit on the name length."
  },
  {
    ques:"Can you have names with emojis",
    ans:"Yes"
  },
  {
    ques:"Why are names registered as hashes?",
    ans:"Hashes provide a fixed length identifier that can easily be passed around between contracts with fixed overhead and no issues passing around variable-length strings."
  },
  {
    ques:"What kinds of behaviours are likely to result in losing ownership of a name?",
    ans:"The TNS TLD registrar is structured such that names, once issued, cannot be revoked so long as an active registration is maintained."
  },
  {
    ques:"Once I own a name, can I create my own subdomains?",
    ans:"Yes. You can create whatever subdomains you wish and assign ownership of them to other people if you desire. You can even set up your own registrar for your domain."
  },
  {
    ques:"Is TNS only for storing an Tron address?",
    ans:"No, you can store the addresses of over 100 blockchains, profile information such as an avatar and Twitter handle, and more."
  },
  {
    ques:"Can I change the address my name points to after I’ve bought it?",
    ans:"Yes, you can update the addresses and other resources pointed to by your name at any time."
  },
  {
    ques:"How much does it cost to renew a name?",
    ans:`Currently, renewable costs are set at the following prices:

    5+ character names: 10 TRX per year.
    4 character names: 25 TRX per year.
    3 character names 200 TRX per year.
    2 character names: 800 TRX per year.
    1 character names 2,000 TRX per year.`
  },
  {
    ques:"How much does it cost to register a name?",
    ans:`Currently, registration costs are set at the following prices:

    5+ character names: 100 TRX.
    4 character names: 500 TRX.
    3 character names 4,000 TRX.
    2 character names: 20,000 TRX.
    1 character names 50,000 TRX.`
  },
  {
    ques:"What happens if I forget to extend the registration of a name?",
    ans:"After your name expires, there is a 90 day grace period in which the owner can't edit the records but can still re-new the name. After the grace period, the name is released for registration by anyone. The released name continues to resolve your TRX address until the new owner overwrites it."
  },
  {
    ques:"What is a Registry?",
    ans:"The core contract of TNS, the registry maintains a mapping from domain name (at any level - x, y.x, z.y.x etc) to owner, resolver,reverse registrant, and time-to-live."
  },
  {
    ques:"What is a Resolver?",
    ans:"A Resolver is a smart contract that holds records. Names are set by default to the Public Resolver managed by the TNS team and has all the standard TNS record types. You can set your Resolver to a custom resolver contract if you,d like."
  },
  {
    ques:"What is a Registrar?",
    ans:"A registrar is a contract responsible for allocating subdomains and additionally responsible for creating TRC721 token. Registrars can be configured at any level of TNS. You can deploy custom registrar for your name and allow your dapp users to create subdomain on it if you,d like."
  },
  {
    ques:"What is a Controller?",
    ans:"Controller can change the resolver and add/edit records. but does not own NFT of the TLD name."
  },
  {
    ques:"What is a Registrant?",
    ans:"Registrant is owner of NFT token of the name and can set controller.there are no registrants for subdomain unless the subdomain is customised to be TRC721 compliant."
  },
  {
    ques:"What is the difference between the Registrant and Controller?",
    ans:"If your Tron address is set as the Controller you can change the resolver and add/edit records. The Registrant only exists on TLD names and it allows you to change the Controller. If you transfer the Registrant to an address you don't own, you lose the ownership of the name."
  },
  {
    ques:"What is a Reverse registrant?",
    ans:"A Primary TNS Name record (formerly Reverse registrant Record) makes your Tron address point to an TNS name. This allows dapps to find and display your TNS name when you connect to them with your Tron account. This can only be set by you so it is not set automatically upon registration."
  },
  {
    ques:"How do I transfer my name?",
    ans:`For a TLD name, transfer both the Registrant and the Controller to the new Ethereum account. Since TLD names are TRC721 compliant NFTs, you can change the Registrant by simply transferring the NFT from any NFT compliant wallet/marketplace as well.

    Note that transferring the ownership (aka the Registrant) of the name does not change the controller nor records, so the recipient may need to update them once received. If the recipient is not experienced or you prefer your address not to be associated to the transferring names, it may be a good idea for you to set the TRX Address record to their Tron address, set the controller, then transfer the name.

    For subdomains, there are no registrants unless the subdomain is customised to be TRC721 compliant. Simply set the controller to the new address (after setting the record to the new address).`
  }
]

const renderContents = () =>{
  return all.map((item,i)=>{
    return <div className="faq__content" key={i}>
      <div className="faq__content__head">
        {item.ques}
      </div>
      <div className="faq__content__body">
        {item.ans}
      </div>
    </div>
  })
}

  return(
    <div className="main">
      <div className = "large__title">
        <h1>FAQ</h1>
        <p>Frequently Asked Questions</p>
      </div>

      <div className="faq__container">
        {renderContents()}
      </div>
    </div>
  )
}



export default Faq;
