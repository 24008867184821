import TronWeb from 'tronweb';
window.Buffer = window.Buffer || require("buffer").Buffer;

const tronWeb = new TronWeb({
  fullHost: 'https://api.trongrid.io',
  eventServer: 'https://api.trongrid.io',
  privateKey :"08089C24EC3BAEB34254DDF5297CF8FBB8E031496FF67B4EFACA738FF9EBD455",
  // headers: { "TRON-PRO-API-KEY": "0afa8102-70e2-4e72-b541-75c7c1b2f61f" },
})

// tronWeb.setHeader({'Content-Type': 'application/json',
//     'Access-Control-Allow-Headers': '*',
//     'Access-Control-Allow-Origin': '*',
//     });

export default tronWeb;
