import React, { Component,useEffect } from 'react'
import './style.scss';



const PageNotFound = () =>{
  useEffect(() => {
    document.title = "Page Not Found | TNS";
    return () => {
        document.title = "Tron Name Service";
    }
  })
  return(
    <div className="main main__search__page page__not_found">
      <h1>404</h1>
      <p>We couldn't find what you were looking for!</p>
    </div>
  )
}



export default PageNotFound;
