import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {useParams,useNavigate} from 'react-router-dom'
import {Models} from '../../components'
import $ from "jquery";

import tronWeb from "../../tronweb"
import {Utils,NameHash} from '../../helper'
import {ReactComponent as TnsLogo} from '../../images/logo.svg';

import './style.scss';

const airdropContract = "TJt4vqnPWQhPXkxZ1Fh9VSRspub9mVnzdq";
// 100000000000
class FreeClaim extends Component {

  constructor(props){
    super(props)
    this.unmounted = false;


    this.state = {
      label:"",
      tld:"trx",
      isProcess:false,
      address:"",
      claimed:false,
      remaining :0,
    }
  }

  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    document.title = "Tron Name Service";
    this.unmounted = true;
  }

  componentDidMount(){
    document.title = "TNS - Free Claim";

    Utils.getUser((err,account)=>{
      if(err) return console.log(err);
      const {address} = account;
      if(address){
        this.setState({address,claimed:false},this.fetchClaimed)
      }else {
        this.setState({address,claimed:false})
      }
    })

    this.fetchRemaing()
  }

  fetchRemaing = async() =>{
    try {
      let {address} = this.state;
      let _airdropContract = await tronWeb.contract().at(airdropContract);
      let _remaining = await _airdropContract.limit().call();
      _remaining = tronWeb.toDecimal(_remaining);
      this.setState({remaining:_remaining})
    } catch (e) {
      this.setState({remaining:0})
      // console.log(e);
    }
  }

  fetchClaimed = async() =>{
    try {
      let {address} = this.state;
      let _airdropContract = await tronWeb.contract().at(airdropContract);
      let claimed = await _airdropContract.claimed(address).call();
      this.setState({claimed})
    } catch (e) {
      this.setState({claimed:true})
      console.log(e);
    }
  }


  handleInput = (e) =>{
    let value = e.target.value;
    if(!!value && value !== NameHash.sanitize(value,true)) return ;
    this.setState({label:value.toLowerCase()})
  }


  handleSubmit = async(e) =>{
    try {
      e.preventDefault();
      let{label,tld,address,isProcess} = this.state;

      if(!label) return Utils.setToastAlert("Please enter TNS name","warning");
      if(label.length < 5) return Utils.setToastAlert("Please enter 5 or more letters TNS name","warning");
      if(!address) return $(".login__access").click();
      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let registrar = await window.tronWeb.contract().at(Utils.registrar(tld));
      let _available = await registrar.available(label).call();
      if(!_available){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Name not available, please enter other name.","warning");
      }

      let _airdropContract = await window.tronWeb.contract().at(airdropContract);
      await _airdropContract.freeMint(label,Utils.registrar(tld)).send({
       feeLimit:200 * 1e6,
      });

      Utils.setToastAlert("Domain claim request submitted successfully","success")
      await Utils.wait(3000);
      window.location.href = `/name/${label}.${tld}/details`

    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to claim name","error")
      }
    }
  }


  render(){
    let {label,tld,claimed,address,remaining,isProcess} = this.state;

    return(
      <div className="main main__full__page">


        <div className="free__claim__form">
          <div className="free__claim__form__body">
            <div className="title"><h1>Claim Your Free Name</h1></div>
            <div className="title"><h2>Hurry up, {remaining} remaining</h2></div>

            <div className="free__claim__input">
              <input
                type="text"
                placeholder="Enter TNS name"
                value={label}
                onChange={this.handleInput}
                readOnly={!!isProcess}
                />
              <Models.TldSelect tld={tld} handler={(val)=>this.setState({tld:val})} key={tld}/>

            </div>

            <div className="free__claim__msg">{!!label?`${label}.${tld}`:""}</div>

            <button
              disabled={!!isProcess || !!claimed || !remaining}
              onClick={this.handleSubmit}
              >
              {!remaining?"All claimed":!claimed?"Claim Now":"Already Claimed"}
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </button>
          </div>
        </div>
      </div>
    )
  }
}



let data = {
  FreeClaim,
}

export default data;
