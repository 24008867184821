import './style.scss';

import Roadmap from "./roadmap.jsx"
import Faq from "./faq.jsx"
import Terminology from "./terminology.jsx"

let data = {
  Roadmap,
  Faq,
  Terminology,
}

export default data;
