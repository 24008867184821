import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {useParams,useNavigate} from 'react-router-dom'
import {Models} from '../../components'

import tronWeb from "../../tronweb"
import {Utils,NameHash} from '../../helper'
import {ReactComponent as TnsLogo} from '../../images/logo.svg';

import './style.scss';



class SearchPage extends Component{

  componentDidMount(){
    document.title = "Search Tron Name";
  }
  componentWillUnmount(){
    document.title = "Tron Name Service";
  }

  render(){
    return(
      <div className="main main__search__page">
        <div className="search___title">
          <TnsLogo/>
        </div>
          <Models.SearchInput data = {{tld:"trx"}}/>
        </div>
    )
  }
}


class SearchList extends Component {

  constructor(props){
    super(props)
    this.unmounted = false;


    this.state = {
      domain:this?.props?.params?.domain??"",
      tld:"trx",
      count:0,
      domains:[],
      isLoading:true,
      address:"",
    }
  }

  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }
  componentWillUnmount(){
    document.title = "Tron Name Service";
    this.unmounted = true;
  }

  componentDidMount(){
    document.title = "Explore Tron Names";

    let{domain,tld} = this.state;

    Utils.getUser((err,account)=>{
      if(err) return console.log(err);
      const {address} = account
      this.setState({address})
    })


    let url = "";
    if(!!tronWeb.isAddress(domain)){
      const urlParams = new URLSearchParams(window.location.search);
      tld = urlParams.get('tld') || tld;
      url = `/search/${domain}?tld=${tld}`;
    }else {
      tld = Utils.getTld(domain);
      domain = NameHash.sanitizeDomainName(domain,tld);
      url = `/search/${domain}`;
    }

    if (window.history.pushState) {
      window.history.pushState(null, null,url);
    }

    this.setState({domain,tld},this.fetchDomains);

  }

  componentDidUpdate(prevProps) {
    let{tld} = this.state;

    let query = this.props?.params?.domain??"";
    let domain = prevProps?.params?.domain??"";

    if(!!tronWeb.isAddress(query)){
      const urlParams = new URLSearchParams(window.location.search);
      let _tld = urlParams.get('tld') || tld;
      if(_tld !== tld || query !== domain){
        this.setState({domain:query,tld:_tld,domains:[],count:0,},this.fetchDomains);
      }
    }else if(!!query && query !== domain){
      tld = Utils.getTld(query);
      this.setState({domain:query,tld,domains:[],count:0,},this.fetchDomains);
    }
  }

  fetchDomains = async() =>{
    try {
      let {domain,tld,domains} = this.state;

      this.setState({isLoading:true})

      if(!tronWeb.isAddress(domain)){
        if(!domains || !domains.length){
          this.setState({isLoading:false},this.fetchDomainRocord)
        }else {
          this.setState({isLoading:false},this.fetchSubDomains)
        }

      }else{
        let registrar = await tronWeb.contract().at(Utils.registrar(tld));
        let balanceOf = await registrar.balanceOf(domain).call();
        balanceOf = tronWeb.toDecimal(balanceOf);
        this.setState({isLoading:false,count:balanceOf},this.fetchAccount)
      }

    } catch (e) {
      this.setState({isLoading:false,domains:[],count:0})
    }
  }

  fetchDomainRocord = async() =>{
    try {
      let {domain,tld} = this.state;
      this.setState({isLoading:true})

      domain = NameHash.sanitizeDomainName(domain,tld);

      let payload = {
        isLoading:false,
        count : 1,
        domains : [{name:domain,expiry:0}],
      }

      let lables = domain.split(".");
      if(lables.length !== 2) return this.setState(payload,this.fetchSubDomains);

      let tokenId = NameHash.nameToTokenid(domain);
      let registrar = await tronWeb.contract().at(Utils.registrar(tld));
      let _owner = await registrar.ownerOf(tokenId).call();
      payload.domains[0].owner = tronWeb.address.fromHex(_owner);

      if(Utils.isZeroAddress(_owner)) return this.setState(payload);

      let expiry = await registrar.methods["nameExpires(uint256)"](tokenId).call();
      expiry = expiry._hex?expiry._hex:expiry;
      expiry = tronWeb.toDecimal(expiry);
      payload.domains[0].expiry = expiry;

      this.setState(payload,this.fetchSubDomains)

    } catch (e) {
      this.setState({isLoading:false,domains:[],count:0})
    }
  }


  fetchSubDomains = async() =>{
    try {
      let {domain,tld,domains,count,isLoading} = this.state;

      if(isLoading) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isLoading:true})

      let node = NameHash.nameToNode(domain);
      let registry = await tronWeb.contract().at(Utils.registry);

      let _count = await new Promise(async(resolve)=>{
        try {
          if(count > 1) return resolve(count);
          let _res = await registry.subDomainCount([node]).call();
          resolve(tronWeb.toDecimal(_res[0])+1)
        } catch (e) {
          resolve(count);
        }
      })
      if(domains.length >= _count) return this.setState({isLoading:false});

      let limit = _count - domains.length >= 10?10:_count - domains.length;
      let start = _count - domains.length - limit;
      let nodes = await registry.searchNodeSubDomains(node,start,limit).call();
      nodes = nodes.reverse();

      let names = await registry.methods["getNames(bytes32[])"](nodes).call();

      let _domains = names.map(i=>{
        return{name:`${i}`,expiry:0}
      })
      await new Promise(async(resolve)=>{
        try {
          if(domain !== tld)return resolve();
          let _tokenIds = names.map(i => NameHash.nameToTokenid(i));

          let registrar = await tronWeb.contract().at(Utils.registrar(tld));
          let owners = await registrar.methods["ownersOf(uint256[])"](_tokenIds).call();

          if(!owners.find(i => !Utils.isZeroAddress(i))) return resolve()
          owners = owners.map(i => tronWeb.address.fromHex(i));

          let expiries = await registrar.methods["nameExpires(uint256[])"](_tokenIds).call();
          expiries = expiries.map(i => tronWeb.toDecimal(i));

          _domains.map((item,i)=>{
            item.owner = owners[i];
            item.expiry = expiries[i]
            return item;
          })
          resolve()
        } catch (e) {
          resolve()
        }
      })
      domains = [...domains,..._domains];

      this.setState({isLoading:false,domains,count:_count})
    } catch (e) {
      this.setState({isLoading:false})
    }
  }

  fetchAccount = async() =>{
    try {
      let {domain,tld,domains,count,isLoading} = this.state;
      if(!count || domains.length >= count)return;
      let limit = count - domains.length >= 10?10:count - domains.length;
      let start = count - domains.length - limit;

      if(isLoading) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isLoading:true})

      let registrar = await tronWeb.contract().at(Utils.registrar(tld));
      let tokenIds = await registrar.tokenSearchOfAccount(domain,start,limit).call();
      tokenIds = tokenIds.map(i => i.toString()).reverse();
      let nodes = tokenIds.map(i => NameHash.tokenIdToNode(i,tld));

      let registry = await tronWeb.contract().at(Utils.registry);
      let names = await registry.methods["getNames(bytes32[])"](nodes).call();
      let expiries = await registrar.methods["nameExpires(uint256[])"](tokenIds).call();
      expiries = expiries.map(i => tronWeb.toDecimal(i));

      let _domains = names.map((name,i)=>{
        return{name,owner:domain,expiry:expiries[i]}
      })
      domains = [...domains,..._domains];
      this.setState({isLoading:false,domains})
    } catch (e) {
      this.setState({isLoading:false})
    }
  }

  handler = (value,_tld) =>{
    let {domain,tld,isLoading} = this.state;
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");

    if(!value) return "";
    if(value === domain && _tld === tld) return "";
    if (window.history.pushState) {
      let url = !tronWeb.isAddress(value)?`/search/${value}`:`/search/${value}?tld=${_tld}`;
      window.history.pushState(null, null,url);
    }

    this.setState({domain:value,tld:_tld,domains:[],count:0,},this.fetchDomains);
  }

  render(){
    let {domain,tld,domains,isLoading,address,count} = this.state;

    let isLoad = !!count && count >10 && count > domains.length;

    return(
      <div className="main">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12">
              <Models.SearchInput
                data = {{tld,label:domain,fullview:true}}
                handler={this.handler}
                key = {domain+tld}
                />
            </div>
            <div className="col-12">
              {!!count && <div className="subdomain__header">
                <p className="subdomain__count">{count} Results</p>
              </div>}

              {!!domains.length && <Models.RenderDomains
                data={{domains,isLoading,address}}
                />}
              {!!isLoading && <Models.RenderDomains
                data={{isLoading,dummy:4}}
                />}
              {!count && !isLoading && <h2 className="noresult__notice">No result found</h2>}

              {!!isLoad && <button
                className="subdomain__loadmore"
                onClick={this.fetchDomains}
                disabled={isLoading}
                >
                Load more
                {!!isLoading && <div className="ball__pulse">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>}
              </button>}


            </div>
          </div>
        </div>
      </div>
    )
  }
}

function SearchResult(props){
    const params = useParams();
    const navigate = useNavigate();

    return (
        <SearchList
            {...props}
            params={params}
            navigate={navigate}
        />
    );
}

let data = {
  SearchPage,
  SearchResult,
}

export default data;
