import React, { Component,useEffect } from 'react'



const Terminology = () =>{

  useEffect(() => {
    document.title = "Terminology | TNS";
    return () => {
        document.title = "Tron Name Service";
    }
  })

  return(
    <div className="main">
      <div className = "large__title">
        <h1>Terminology</h1>
      </div>

      <div className="terminology">
        <p>Label: An individual component of a name, such as 'justin'.</p>
        <p>Labelhash: The keccak256 hash of an individual label.</p>
        <p>Name: A TNS identifier such as 'justin.trx'. Names may consist of multiple parts, called labels, separated by dots.</p>
        <p>Node: A cryptographic hash uniquely identifying a name.</p>
        <p>Namehash: The algorithm used to process a TNS name and return a cryptographic hash uniquely identifying that name. Namehash takes a name as input and produces a node.</p>

        <p>Registry: The core contract of TNS, the registry maintains a mapping from domain name (at any level - x, y.x, z.y.x etc) to owner, resolver,reverse registrant, and time-to-live.</p>
        <p>Resolver: A resolver is a contract that maps from name to the resource (e.g., cryptocurrency addresses, text rocords, etc). Resolvers are pointed to by the resolver field of the registry.</p>
        <p>Registrar: A registrar is a contract responsible for allocating subdomains and additionally responsible for creating TRC721 token as a proof of ownership of subdomains. Registrars can be configured at any level of TNS, and are pointed to by the owner field of the registry.</p>

        <p>Registration: A registration is a registrar's record of a user's ownership of a name. This is distinct from the owner field in the Registry; registrations are maintained in the registrar contract and additionally store information on expiry date.</p>

        <p>Controller: The Controller of a name is the entity referenced in the TNS registry's owner field. A controller may transfer ownership, set a resolver or TTL, and create or reassign subdomains
          and may edit the records of a name. The Controller may be changed by the Registrant or Controller. </p>
        <p>Registrant : The owner of a registration. The registrant may transfer the registration, set the Controller, and reclaim ownership of the name in the registry if required.
          The registrant is the owner of the NFT token that represents the name.</p>

          <p>Reverse registrant: The mapping from a TRON address (eg, T9yD14...) to a TNS name.This allows dapps to find and display your TNS name when you connect to them with your Tron account. You may own multiple domain or subdomain but address to TNS domain/subdomain mapping is unique. A address cannot map to two TNS domain/subdomain or vice versa. so,If your address is already associated to other TNS domain/subdomain in REVERSE REGISTRY, It will be automatically removed.</p>
      </div>
    </div>
  )
}



export default Terminology;
