import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as Parts from './components.jsx'
import $ from "jquery";

import './style.scss';

class Home extends Component{
  constructor(props) {
    super(props)
    this.unmounted = false;

    this.state = {
      scrolling:false,
      up:false,
    }
  }

  setState(state, callback) {
    if (!this.unmounted) {
        super.setState(state, callback);
    }
  }

  componentDidMount(){
    document.title = "Tron Name Service";
    window.addEventListener('mousewheel', this.scrollPages,{ passive: false });
    window.addEventListener('scroll', this.switchArrow,{ passive: true });
  }
  componentWillUnmount(){
    this.unmounted = true;
    window.removeEventListener('mousewheel', this.scrollPages,{ passive: false });
    window.addEventListener('scroll', this.switchArrow,{ passive: true });
    document.title = "Tron Name Service";
  }

  switchArrow = () =>{
    let {up} = this.state;
    this.setState({up:window.scrollY >= $("#tab-2").offset().top})
  }

  handleTop = () =>{
    window.scrollTo({top: 0,left: 0, behavior: 'smooth'})
  }


  scrollPages = (e) =>{
    if (!!window.safari) return;
    e.preventDefault();

    let {scrolling} = this.state;
    let self = this;

    if(!!scrolling) return;
    this.setState({scrolling:true})

    let tab1 = $("#tab-1").offset().top;
    let tab2 = $("#tab-2").offset().top;
    let tab3 = $("#tab-3").offset().top;
    let tab4 = $("#tab-4").offset().top;
    let _y = window.scrollY;
    let scrollPositin =  window.scrollY;

    if(e.wheelDelta > 0){
      //up
      scrollPositin = _y > tab3?tab3-50:_y > tab2?tab2-50:_y > tab1?0:_y;
    }else {
      scrollPositin = _y < tab2-51?tab2-50:_y < tab3-51?tab3-50:_y < tab4-51?tab4-50:_y;
    }
    window.scrollTo({top: scrollPositin,left: 0, behavior: 'smooth'});

    const myTimeout = setTimeout(function(){
      clearTimeout(myTimeout);
      self.setState({scrolling:false})
    }, 500);

  }

  render(){
    let {up} = this.state;

    return(
      <div className="main">

        <div className="home__banner" id="tab-1">
          <div className="banner__panel">
            <h1 className="banner__title">Decentralised naming for wallets, websites, & more.</h1>
            <div className="banner__btns">
              <Link to="/search/trx" className="banner__btn">Explore</Link>
              <Link to="/search" className="banner__btn">Register</Link>
            </div>
          </div>
        </div>
        <Parts.SetUpDomain />
        <Parts.UserInfo />
        <Parts.AddressInfo/>

        {!!up && <div className="scroll_btn" onClick={this.handleTop}>
          <svg fill="none" height="24" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><line x1="12" x2="12" y1="4" y2="20"/><polyline points="18 14 12 20 6 14"/></svg>
        </div>}

      </div>
    )
  }
}

export default Home;
