import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {useParams,useNavigate} from 'react-router-dom'
import $ from "jquery";

import tronWeb from "../../tronweb"
import {Utils,NameHash} from '../../helper'
import {ReactComponent as ArrowIcon} from '../../images/arrow-left.svg';

import './style.scss';

class TldSelect extends Component {
  constructor(props) {
      super(props)
      this.state = {
        isPopup:false,
      }
  }

  componentDidMount(){
   document.addEventListener('mouseup', this.fireEvents,{passive:true});
  }

   componentWillUnmount(){
    document.removeEventListener('mouseup', this.fireEvents,{passive:true});
  }

  fireEvents= (e)=>{
    let{isPopup} = this.state;
    if (!!isPopup && $(e.target).closest(".tld__select").length === 0){
      this.setState({isPopup:false})
    }
  }
  renderTlds = () =>{
    return Utils.allRegistrar().map(i=>{
      return <div className="tld__option" onClick={()=>this.props.handler(i)} key={i}>{i}</div>
    })
  }
  render(){
    let{isPopup} = this.state;
    let{tld} = this.props;
    return(
      <div className={`tld__select${isPopup?" active":""}`} onClick={()=>this.setState({isPopup:!isPopup})}>
        <div className="tld__selected">{tld}<ArrowIcon/></div>
        <div className="tld__options">
          {this.renderTlds()}
        </div>
      </div>
    )
  }
}


class SearchInput extends Component{

  constructor(props){
    super(props)
    this.state = {
      label:this?.props?.data?.label??"",
      tld:this?.props?.data?.tld??"tns",
    }
  }
  handleInput = (e) =>{
    let value = e.target.value;

    if(!!value && value !== NameHash.sanitize(value)) return;
    this.setState({label:value})
  }
  handleSubmit = (e) =>{
    e.preventDefault();
    let{label,tld} = this.state;
    // if(!label) return;
    let _domain = !!tronWeb.isAddress(label)?label:NameHash.sanitizeDomainName(label,tld);

    if(this.props.handler){
      this.props.handler(_domain,tld);
    }else {
      window.location.href = `/search/${_domain}`;
    }
  }
  render(){
    let{label,tld} = this.state;
    let {fullview} = this.props?.data??{};
    return(
      <form className={`search__bar ${!!fullview?"search__bar__full":""}`} onSubmit={this.handleSubmit.bind(this)}>
        <div className="serach__input">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
          <input
            type="text"
            placeholder="Search name or address"
            autoFocus={!fullview}
            value={label}
            onChange={this.handleInput}
            />
        </div>
        <TldSelect tld={tld} handler={(val)=>this.setState({tld:val})} key={tld}/>
        <button className="serach__btn" type="submit">
          Search
        </button>
      </form>
    )
  }
}



const RenderDomains = (props) =>{

  let {domains,isLoading,address,dummy} = props?.data??{};
  domains = domains || [];

  return(
    <div className="row no-gutters">
      {!domains.length && !!isLoading && new Array(dummy?dummy:5).fill(undefined).map((_,i)=>{
          return(
            <div className="col-12" key={i}>
              <div className = "domain__list dummy__domain__list">
                <div className = "domain__list__inner">
                  <div className = "domain__list__name">&nbsp;</div>
                  <div className = "domain__list___info">
                    <p>&nbsp;</p>
                    <p className = "domain__list___info__status">&nbsp;</p>
                  </div>
                  <p className = "domain__list__expiry">&nbsp;</p>
                </div>
              </div>

            </div>
          )
        })}
      {!!domains.length && domains.map((domain,i)=>{
        let {name,expiry,owner} = domain || {};
        let gracePeriod = 90*24*60*60; //7776000
        let expireIn = Math.floor(expiry + gracePeriod - (Date.now()/1000));
        let isSubDomain = name.split(".").length >2 || !domain.hasOwnProperty("owner");
        let isAvailabble = !isSubDomain && (!expiry || expireIn <= 0);
        let _url = `/name/${name}/${isAvailabble?"register":"details"}`;
          return(
            <div className="col-12" key={name}>
              <Link className = "domain__list" to={_url}>
                <div className = "domain__list__inner">
                  <div className = "domain__list__name">
                    {name}
                  </div>
                  <div className = "domain__list___info">
                    {!!expiry && <p>Expires {Utils.getTimeInGMT(expiry*1000)}</p>}
                    <p className = "domain__list___info__status">
                      {!!isSubDomain?"--":!!isAvailabble?"Available":address !== owner?"Unavailable": expireIn <= gracePeriod? "Renew":"Extend"}
                    </p>
                  </div>
                  {!!expiry && <p className = "domain__list__expiry">Expires {Utils.getTimeInGMT(expiry*1000)}</p>}

                </div>
              </Link>

            </div>
          )
        })}
    </div>
  )
}

let data = {
  TldSelect,
  SearchInput,
  RenderDomains
}

export default data;
